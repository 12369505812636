@use '@angular/material' as mat;


// Define primary, accent, and warn palettes
$lissa-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$lissa-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$lissa-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Define the theme using define-light-theme to automatically include foreground and background
$lissa-theme: mat.m2-define-light-theme((
	color: (
		primary: $lissa-primary,
		accent: $lissa-accent,
		warn: $lissa-warn,
	),
	typography: mat.m2-define-typography-config(
	),
	density: 0,
));

// Apply the theme to all components
@include mat.all-component-themes($lissa-theme);

html, body {
	height: 100%;
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
	font-family: annabelle-jf;
	src: url('assets/fonts/Annabelle_JF_Regular.ttf') format("opentype");
}

$gray:#4E4E4E;

.vertical-box {
	display: flex;
	flex-direction: column;
}

.horizontal-box{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

:focus.icon-no-focus {
	outline: unset;
}

.centered {
	align-items: center;
	justify-content: center;
}

#horizontal-line{
	width: 90%;
	border-bottom: 1px solid black;
}

table{
	width: 100%;
	margin-bottom: 32px;
}
.whiteButton {
	color: white !important;
}
.image{
	width: 100%;
	height: auto;
}

h1 {
	font-size: 24px;
	font-weight: 400;
}

h2 {
	font-size: 20px;
	font-weight: 500;
}
